import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Table,
  FormGroup,
  FormLabel,
  FormControl
} from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import './StatisticsPage.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getStatistics } from 'redux-layer/actions';
import { DetailViewItem } from 'components/DetailItemView';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Rectangle,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { AIStatistics } from 'components/AIStatistics';
import { CustomerSelect } from 'components/CustomerSelect';

const labels = {
  generated_videos: 'Video Generations',
  submitted: 'Image Submissions',
  ai_generated: 'Image Generations',
  auto_motions_count: 'Auto Motions',
  parallax_motions_count: 'Parallax Motions',
  prompt_motions_count: 'Prompt Motions',
  customer_credits_used: 'Customer Credits',
  vimmerse_credits_used: 'Paying Credits',
  count: 'Count',
  nsfw_count: '# of NSFW',
  failed_count: '# of Failed'
};

const CustomLegend = props => {
  const { payload } = props;
  return (
    <div className='custom-legend'>
      {payload.map(item => (
        <div className='legend-item' style={{ color: item.color }}>
          <div className='color-box' style={{ background: item.color }} />
          {labels[item.payload.dataKey]}
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label, showDay = false }) => {
  if (active && payload && payload.length) {
    let dateString = '';
    if (payload[0] && payload[0].payload && payload[0].payload.day) {
      dateString = moment(payload[0].payload.day).format('MM/DD/YYYY');
    }
    return (
      <div className='custom-tooltip'>
        {payload.map(item => (
          <p className='label'>
            {`${labels[item['dataKey']] || item['dataKey']}`}:{' '}
            <strong>{item.value}</strong>
          </p>
        ))}
        {showDay && dateString && <p className='label'>Date: {dateString}</p>}
      </div>
    );
  }

  return null;
};

const sumUpObjects = (obj1, obj2) => {
  return Object.entries(obj2).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
    { ...obj1 }
  );
};

function mergeObjects(objects) {
  return objects.reduce((acc, obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'number') {
        acc[key] = (acc[key] || 0) + obj[key];
      } else if (typeof obj[key] === 'object') {
        acc[key] = mergeObjects([acc[key] || {}, obj[key]]);
      } else if (Array.isArray(obj[key])) {
        acc[key] = [...(acc[key] || []), ...obj[key]];
      }
    }
    return acc;
  }, {});
}

const StatisticsPage = props => {
  const [customers, setCustomers] = useState([]);
  const customer_id = 'vimmerse-product';
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().subtract(1, 'days').toDate(),
      key: 'selection'
    }
  ]);

  const [topUserCount, setTopUserCount] = useState(50);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const startDate = moment(dateRange[0].startDate);
    let endDate = moment(dateRange[0].endDate);

    if (endDate.isBefore(startDate)) {
      endDate = startDate;
    }

    const startDateString = startDate.format('YYYY-MM-DD');
    const endDateString = endDate.format('YYYY-MM-DD');

    dispatch(getStatistics(startDateString, endDateString));
  }, [customer_id, dateRange, dispatch]);

  const handleSelect = ranges => {
    if (ranges && ranges.selection) {
      if (ranges.selection.startDate < ranges.selection.endDate) {
				setDateRange([ranges.selection]);
			}
    }
  };

  const { isLoadingStatistics, statistics } = useSelector(state => ({
    statistics: state.statistic.statistics || [],
    isLoadingStatistics: state.statistic.isLoadingStatistics
  }));

  const selectedCustomerIds = useMemo(() => {
    return customers
      ? Array.isArray(customers)
        ? customers.map(c => c.customer_id)
        : [customers]
      : [];
  }, [customers]);

  const monthUserCreated = useMemo(() => {
    const monthUserStatistics = []
    statistics.forEach(dayItem => {
      const { media_statistics } = dayItem;
      if (!media_statistics) {
        return;
      }

      Object.keys(media_statistics).forEach(customer_id => {
        if (
          (selectedCustomerIds.length &&
            selectedCustomerIds.includes(customer_id)) ||
          !selectedCustomerIds.length
        ) {
          const customer_statistics = media_statistics[customer_id];
          if (!customer_statistics) {
            return;
          }
          const { user_created } = customer_statistics;

          if (!user_created) {
            return;
          }

          const userCreated = JSON.parse(user_created);
          Object.keys(userCreated).forEach(user_id => {
            if (monthUserStatistics[user_id]) {
              monthUserStatistics[user_id] += userCreated[user_id];
            } else {
              monthUserStatistics[user_id] = userCreated[user_id];
            }
          });
        }
      });
    });
    return monthUserStatistics;
  }, [selectedCustomerIds, statistics]);

  const uniqueUsers = useMemo(() => {
    return Object.keys(monthUserCreated).length;
  }, [monthUserCreated]);

  const sortedUserCreated = useMemo(() => {
    const userCreatedMap = [];
    Object.keys(monthUserCreated).forEach(user_id => {
      userCreatedMap.push({
        user_id,
        count: monthUserCreated[user_id]
      });
    });

    return userCreatedMap
      .sort((a, b) => (a.count < b.count ? 1 : -1))
      .slice(0, Number(topUserCount));
  }, [topUserCount, monthUserCreated]);

  const aiToolStatistics = useMemo(() => {
    const toolStatistics = {};
    statistics.forEach(dayStatistic => {
      const dayToolStatistics = dayStatistic.tool_statistics;
      Object.keys(dayToolStatistics).forEach(toolKey => {
        if (toolStatistics[toolKey]) {
          const countData = dayToolStatistics[toolKey].count;
          const newCountData = sumUpObjects(
            countData,
            toolStatistics[toolKey].count
          );
          toolStatistics[toolKey] = { count: newCountData };
        } else {
          toolStatistics[toolKey] = {
            count: { ...dayToolStatistics[toolKey].count }
          };
        }
      });
    });
    return toolStatistics;
  }, [statistics]);

  const assetStatistics = useMemo(() => {
    return statistics.map(dayStatistic => {
      let sum = 0;
      const dayAssetsCreated = dayStatistic.asset_statistics;

      const assetsCreatedByDay = Object.keys(dayAssetsCreated).map(
        customer_id => {
          if (selectedCustomerIds.includes(customer_id)) {
            return dayAssetsCreated[customer_id];
          }
          return {};
        }
      );

      const newStatistics = mergeObjects(assetsCreatedByDay);

      Object.keys(newStatistics).forEach(option => {
        if (option !== 'day') {
          sum += newStatistics[option];
        }
      });

      return { ...newStatistics, day: dayStatistic['id'], sum: sum };
    });
  }, [statistics, selectedCustomerIds]);

  const imageGenerated = useMemo(() => {
    let sum = 0;
    assetStatistics.forEach(stat => {
      Object.keys(stat).forEach(option => {
        if (option !== 'day' && option !== 'sum') {
          sum += stat[option];
        }
      });
    });
    return sum;
  }, [assetStatistics]);

  const imageGeneratedMap = useMemo(() => {
    const assetMap = mergeObjects(assetStatistics);
    const optionMap = [];
    Object.keys(assetMap).forEach(option => {
      if (option !== 'day' && option !== 'sum') {
        optionMap.push({
          option: option,
          count: assetMap[option]
        });
      }
    });

    return optionMap;
  }, [assetStatistics]);

  const mediaStatistic = useMemo(() => {
    const mediaData = statistics.map(dayStatistic => {
      const dayMediaStatistics = dayStatistic.media_statistics;
      const dayStatistics = {};
      Object.keys(dayMediaStatistics).forEach(tool_key => {
        dayStatistics[tool_key] = { ...dayMediaStatistics[tool_key] };
        delete dayStatistics[tool_key].user_created;
      });

      const customersMediaData = selectedCustomerIds.map(customer_id => {
        if (dayStatistics[customer_id]) {
          return dayStatistics[customer_id];
        }
        return {};
      });
      const newDayStatistics = mergeObjects(customersMediaData);
      return { ...newDayStatistics, day: dayStatistic['id'] };
    });
    return mediaData;
  }, [statistics, selectedCustomerIds]);

  const orderedStatistics = mediaStatistic.sort((a, b) =>
    a.day < b.day ? -1 : 1
  );

  const aiMotionStatistics = useMemo(() => {
    const motions = orderedStatistics.map(stat => stat.auto_motions);
    const mergedMotions = mergeObjects(motions);

    return Object.keys(mergedMotions).map(mt => ({
      motion_key: mt,
      count: mergedMotions[mt]
    }));
  }, [orderedStatistics]);

  const parallaxMotionStatistics = useMemo(() => {
    const motions = orderedStatistics.map(stat => stat.parallax_motions);
    const mergedMotions = mergeObjects(motions);

    return Object.keys(mergedMotions).map(mt => ({
      motion_key: mt,
      count: mergedMotions[mt]
    }));
  }, [orderedStatistics]);

  const voteStatistics = useMemo(() => {
    const votes = orderedStatistics.map(stat => stat.votes);
    const mergedMotions = mergeObjects(votes);

    return Object.keys(mergedMotions).map(mt => ({
      vote_key: mt,
      count: mergedMotions[mt]
    }));
  }, [orderedStatistics]);

  const totalRatings = useMemo(() => {
    return Object.values(voteStatistics).reduce((prev, current) => {
      return prev + current.count;
    }, 0);
  }, [voteStatistics]);

  const totalSubmissions = useMemo(() => {
    const sum = mediaStatistic.reduce((prevSum, currentValue) => {
      return prevSum + currentValue['submitted'];
    }, 0);
    return sum;
  }, [mediaStatistic]);

  const totalGenerated = useMemo(() => {
    const sum = mediaStatistic.reduce((prevSum, currentValue) => {
      return prevSum + currentValue['generated_videos'];
    }, 0);
    return sum;
  }, [mediaStatistic]);

  // const vimmerseUsed = customerStatistics.reduce((prevSum, currentValue) => {
  //   return prevSum + (currentValue['vimmerse_credits_used'] || 0);
  // }, 0);

  // const totalCredits = totalGenerated * 20 + imageGeneated * 4;

  return (
    <div className='user-statistics-page'>
      <div className='container-fluid'>
        <div className='page-header'>
          <h1>
            Statistics
            {isLoadingStatistics && <i className='fas fa-redo fa-spin' />}
          </h1>
          <Button
            variant='primary'
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-3'>
            <div className='date-range-picker-wrapper'>
              <DateRangePicker ranges={dateRange} onChange={handleSelect} />
            </div>
          </div>
          <div className='col-lg-6 mb-3'>
            <div className='row'>
              <div className='col-md-2'>
                <DetailViewItem title='Submitted' value={totalSubmissions} />
              </div>
              <div className='col-md-2'>
                <DetailViewItem
                  title='Video Generated'
                  value={totalGenerated}
                />
              </div>
              <div className='col-md-2'>
                <DetailViewItem
                  title='Image Generated'
                  value={imageGenerated}
                />
              </div>
              <div className='col-md-2'>
                <DetailViewItem
                  title='Unique Users'
                  value={uniqueUsers}
                />
              </div>
              {/* <div className='col-md-2'>
                <DetailViewItem
                  title='Customer Credits'
                  value={totalCredits - vimmerseUsed}
                />
              </div>
              <div className='col-md-2'>
                <DetailViewItem title='Vimmerse Credits' value={vimmerseUsed} />
              </div>
              <div className='col-md-2'>
                <DetailViewItem title='Total Credits' value={totalCredits} />
              </div> */}
            </div>
            <div className='row'>
              {/* <div className='col-md-2'>
                <DetailViewItem
                  title='Unique Users'
                  value={Object.values(monthUserCreated).length}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div>
          <AIStatistics data={aiToolStatistics} />
        </div>
        <hr />
        <h2>Medias</h2>
        <div className='row'>
          <div className='col-6'>
            <CustomerSelect
              isMulti
              onChangeCustomer={setCustomers}
              customer={customers}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Media Submitted vs Generated by Date</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <LineChart width={800} height={400} data={orderedStatistics}>
                  <Line
                    type='monotone'
                    dataKey='generated_videos'
                    stroke='#1fe074'
                  />
                  <Line type='monotone' dataKey='submitted' stroke='#0b1d78' />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis dataKey='day' padding='no-gap'
                    interval={0}
                    angle={-10} />
                  <YAxis />
                  <Tooltip content={<CustomTooltip showDay={true} />} />
                  <Legend content={<CustomLegend />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>AI Motions vs Parallax Motions by Date</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <LineChart width={800} height={400} data={orderedStatistics}>
                  <Line
                    type='monotone'
                    dataKey='auto_motions_count'
                    stroke='#1fe074'
                  />
                  <Line
                    type='monotone'
                    dataKey='parallax_motions_count'
                    stroke='#0b1d78'
                  />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis dataKey='day' padding='no-gap'
                    interval={0}
                    angle={-10} />
                  <YAxis />
                  <Tooltip content={<CustomTooltip showDay={true} />} />
                  <Legend content={<CustomLegend />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Total ({totalRatings})Votes</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart width={800} height={400} data={voteStatistics}>
                  <Bar
                    dataKey='count'
                    fill='#0b1d78'
                    activeBar={<Rectangle fill='pink' stroke='blue' />}
                  />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis dataKey='vote_key' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Failed vs NSFW by Date</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart width={800} height={400} data={orderedStatistics}>
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <Bar dataKey='nsfw_count' fill='#00a9b5' />
                  <Bar dataKey='failed_count' fill='#1fe074' />
                  <XAxis dataKey='day' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip showDay />} />
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>AI Motion by Type</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart width={800} height={400} data={aiMotionStatistics}>
                  <Bar dataKey='count' fill='#00a9b5' />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis
                    dataKey='motion_key'
                    padding='no-gap'
                    interval={0}
                    angle={-10}
                  />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Parallax Motion by Type</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={800}
                  height={400}
                  data={parallaxMotionStatistics}
                >
                  <Bar dataKey='count' fill='#00a9b5' />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis dataKey='motion_key' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Credit Used by Date</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart width={800} height={400} data={orderedStatistics}>
                  <Bar dataKey='vimmerse_credits_used' fill='#00a9b5' />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis
                    dataKey='day'
                    padding='no-gap'
                    interval={0}
                    angle={-10}
                  />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <hr />
        <h2>Assets</h2>
        <div className='row'>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Image Generations By Date</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <LineChart width={800} height={400} data={assetStatistics}>
                  <Line type='monotone' dataKey='sum' stroke='#0b1d78' />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis dataKey='day' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip showDay={true} />} />
                  <Legend content={<CustomLegend />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Image by Tools</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart width={800} height={400} data={imageGeneratedMap}>
                  <Bar
                    dataKey='count'
                    fill='#0b1d78'
                    activeBar={<Rectangle fill='pink' stroke='blue' />}
                  />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis
                    dataKey='option'
                    padding='no-gap'
                    interval={0}
                    angle={-10}
                  />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <hr />
        <h2>Users</h2>
        <div className='row'>
          <div className='col-12 mb-3 mt-3'>
            <FormGroup>
              <FormLabel>Number of Top Users (Max 100)</FormLabel>
              <FormControl
                type='text'
                value={topUserCount}
                placeholder='50'
                onChange={e => setTopUserCount(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Number of Submissions per User</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart width={800} height={400} data={sortedUserCreated}>
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <Bar dataKey='count' fill='#00a9b5' />
                  <XAxis />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className='col-lg-6 mb-3 mt-3'>
            <h5>Users by submissions</h5>
            <div className='list-wrapper'>
              <Table striped responsive>
                <thead>
                  <td>No</td>
                  <td>Username</td>
                  <td>Created Count</td>
                </thead>
                <tbody>
                  {sortedUserCreated.map((item, index) => (
                    <tr key={item.user_id}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={`/users/${item.user_id.toLowerCase()}`}
                          className='text-info'
                          target='_blank'
                        >
                          {item.user_id}
                        </Link>
                      </td>
                      <td>{item.count}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { StatisticsPage };
