import moment from 'moment';
import { getEnvironment } from 'helper/utils';
import { useCallback, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Rectangle,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const labels = {
	'media_statistics.generated_videos': 'Video Generations',
	
	submitted: 'Image Submissions',
	ai_generated: 'Image Generations',
	auto_motions_count: 'Auto Motions',
	parallax_motions_count: 'Parallax Motions',
	prompt_motions_count: 'Prompt Motions',
	customer_credits_used: 'Customer Credits',
	vimmerse_credits_used: 'Paying Credits',
	count: 'Count',
	nsfw_count: '# of NSFW',
	failed_count: '# of Failed'
};

const CustomLegend = props => {
	const { payload } = props;
	return (
		<div className='custom-legend'>
			{payload.map(item => (
				<div className='legend-item' style={{ color: item.color }}>
					<div className='color-box' style={{ background: item.color }} />
					{labels[item.payload.dataKey]}
				</div>
			))}
		</div>
	);
};

const CustomTooltip = ({ active, payload, label, showDay = false }) => {
	if (active && payload && payload.length) {
		let dateString = '';
		if (payload[0] && payload[0].payload && payload[0].payload.day) {
			dateString = moment(payload[0].payload.day).format('MM/DD/YYYY');
		}
		return (
			<div className='custom-tooltip'>
				{payload.map(item => (
					<p className='label'>
						{`${labels[item['dataKey']] || item['dataKey']}`}:{' '}
						<strong>{item.value}</strong>
					</p>
				))}
				{showDay && dateString && <p className='label'>Date: {dateString}</p>}
			</div>
		);
	}

	return null;
};

const StatisticsSection = ({ currentCustomer }) => {
  const [statisticsData, setStatisticsData] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().subtract(1, 'days').toDate(),
      key: 'selection'
    }
  ]);

  const handleSelect = ranges => {
    if (ranges && ranges.selection) {
      if (ranges.selection.startDate < ranges.selection.endDate) {
        setDateRange([ranges.selection]);
      }
    }
  };

  const fetchStatistics = useCallback(
    async (key, startDateString, endDateString) => {
      const url = `${
        getEnvironment().API_URL
      }customer-statistics/${startDateString}/${endDateString}`;
			try {
				const response = await fetch(url, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'X-Api-Key': `${key}`
					}
				});
				const data = await response.json();
				return data.data;
			} catch (error) {
				console.log(error);
				return [];
			}
    },
    []
  );

  useEffect(() => {
    if (currentCustomer) {
      // fetch statistics
      const { key } = currentCustomer;
      const startDate = moment(dateRange[0].startDate);
      const endDate = moment(dateRange[0].endDate);
      const startDateString = startDate.format('YYYY-MM-DD');
      const endDateString = endDate.format('YYYY-MM-DD');
      fetchStatistics(key, startDateString, endDateString).then(data => {
        setStatisticsData(data);
      });
    }
  }, [dateRange, currentCustomer, setStatisticsData, fetchStatistics]);

  return (
    <div>
      <h2 className='mb-3'>Statistics</h2>
      <div className='col-lg-6 mb-3'>
        <div className='date-range-picker-wrapper'>
          <DateRangePicker ranges={dateRange} onChange={handleSelect} />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Total Members</h5>
              <p className='card-text'>10</p>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Total Orders</h5>
              <p className='card-text'>20</p>
            </div>
          </div>
        </div>
      </div>
			<div className='row'>
				<div className='col-12'>
					<div className='chart-wrapper'>
						<ResponsiveContainer width='100%' height='100%'>
							<LineChart width={800} height={400} data={statisticsData}>
								<Line
									type='monotone'
									dataKey='media_statistics.generated_videos'
									stroke='#1fe074'
								/>
								<CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
								<XAxis dataKey='id' padding='no-gap'
									interval={0}
									angle={-10} />
								<YAxis />
								<Tooltip content={<CustomTooltip showDay={true} />} />
								<Legend content={<CustomLegend />} />
							</LineChart>
						</ResponsiveContainer>
					</div>
					
				</div>
			</div>
    </div>
  );
};

export default StatisticsSection;
