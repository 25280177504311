import React from 'react';
import { UserName } from 'components/UserName';
import { convertUTCtoLocal, getUserAttribute } from 'helper/utils';
import { Button, Modal, Table } from 'react-bootstrap';
import { CSVLink } from "react-csv";

const RedeemedUsersDialog = ({ onClose, redeemedUsers }) => {
  const getUserData = (user) => {
    if (!user.user_info) {
      return {
        user_id: user.user_id,
        email: '',
        given_name: '',
        family_name: '',
        created_at: formatRedeemedDate(user.redeemed_at)
      };
    }

    const attributes = user.user_info.UserAttributes;
    return {
      user_id: user.user_id,
      email: getUserAttribute(attributes, 'email') || '',
      given_name: getUserAttribute(attributes, 'given_name') || '',
      family_name: getUserAttribute(attributes, 'family_name') || '',
      created_at: formatRedeemedDate(user.redeemed_at)
    };
  };

  const formatRedeemedDate = (date) => convertUTCtoLocal(date).format('yyyy-MM-DD HH:mm');

  const csvHeaders = [
    { label: 'UserID', key: 'user_id' },
    { label: 'Email', key: 'email' },
    { label: 'First Name', key: 'given_name' },
    { label: 'Last Name', key: 'family_name' },
    { label: 'Date', key: 'created_at' }
  ];

  const csvData = redeemedUsers.map(getUserData);

  const renderUserRow = (user) => {
    const userData = getUserData(user);

    return (
      <tr key={user.user_id}>
        <td><UserName primary_user_id={user.user_id} /></td>
        <td>{userData.email}</td>
        <td>{`${userData.given_name} ${userData.family_name}`}</td>
        <td>{userData.created_at}</td>
      </tr>
    );
  };

  return (
    <Modal 
      show={true} 
      onHide={onClose} 
      backdrop='static' 
      size='xl' 
      className='user-credit-history-dialog'>

      <Modal.Header closeButton>
        <Modal.Title>Redeemed Users</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>UserID</th>
              <th>Email</th>
              <th>Name</th>
              <th>Redeemed Date</th>
            </tr>
          </thead>
          <tbody>
            {redeemedUsers.length > 0 
              ? redeemedUsers.map(renderUserRow) 
              : (
                <tr>
                  <td colSpan="4" className="text-center">No redeemed users found.</td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <CSVLink data={csvData} headers={csvHeaders} filename="redeemed_users.csv">
          <Button variant='primary'>Export to CSV</Button>
        </CSVLink>
        <Button variant='secondary' onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export { RedeemedUsersDialog };
