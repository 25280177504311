import {
  Button,
  Modal,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserCreditHistoryDialog = ({ onClose, creditHistory }) => {
  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='xl' className='user-credit-history-dialog'>
      <Modal.Header>Credit History</Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Credits</th>
              <th>Media ID</th>
              <th>Customer</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              creditHistory && creditHistory.length > 0 && creditHistory.map((item) => (
                <tr>
                  <td>{item.created_at}</td>
                  <td>{item.credit}</td>
                  <td><Link to={`/media/${item.media_id}`}>{item.media_id}</Link></td>
                  <td>{item.customer_id}</td>
                  <td>{item.type}</td>
                  <td>{item.description}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { UserCreditHistoryDialog };
