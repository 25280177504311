import { useSelector } from 'react-redux';
import './AIToolFailures.scss';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Media ID',
    selector: row => (
      <Link to={`/media/${row.media_id}`} className='text-info'>
        {row.media_id}
      </Link>
    )
  },
  {
    name: 'Motion Type',
    selector: row => row.motion_type,
    sortable: true
  },
  {
    name: 'Requested At',
    selector: row => row.requested_at,
    sortable: true
  },
  {
    name: 'Status',
    selector: row => row.status,
    sortable: true
  }
];

const AIToolFailures = props => {
  const { recentFailures } = useSelector(state => ({
    recentFailures: state.statistic.recentFailures
  }));

  return (
    <div className='ai-tool-failures'>
      <DataTable
        columns={columns}
        data={recentFailures}
        defaultSortFieldId={3}
				defaultSortAsc={false}
        pagination
        striped
        pointerOnHover
        paginationRowsPerPageOptions={[5, 10, 20]}
        paginationPerPage={5}
      />
    </div>
  );
};

export { AIToolFailures };
