import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';


export const getAIRecentHistory =
  () =>
  async dispatch => {
    dispatch({ type: startReqActionType(actionConstants.GET_AI_RECENT_USAGE) });
    try {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      const data = await fetch(
        `${
          getEnvironment().API_URL
        }statistics/ai-tools/recent`,
        requestOptions
      );
      const retData = await processResponse(data);
      dispatch({
        type: successReqActionType(actionConstants.GET_AI_RECENT_USAGE),
        payload: retData
      });
    } catch (error) {
      dispatch({ type: failReqActionType(actionConstants.GET_AI_RECENT_USAGE) });
    }
  };

export const getStatistics =
  (start_date, end_date) =>
  async dispatch => {
    dispatch({ type: startReqActionType(actionConstants.GET_STATISTICS) });
    try {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      const data = await fetch(
        `${
          getEnvironment().API_URL
        }statistics/${start_date}/${end_date}`,
        requestOptions
      );
      const retData = await processResponse(data);
      dispatch({
        type: successReqActionType(actionConstants.GET_STATISTICS),
        payload: retData
      });
    } catch (error) {
      dispatch({ type: failReqActionType(actionConstants.GET_STATISTICS) });
    }
  };

export const getAIRecentFailures =
  () =>
  async dispatch => {
    dispatch({ type: startReqActionType(actionConstants.GET_AI_RECENT_FAILURES) });
    try {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      const data = await fetch(
        `${
          getEnvironment().API_URL
        }statistics/ai-tools/recent-failures`,
        requestOptions
      );
      const retData = await processResponse(data);
      dispatch({
        type: successReqActionType(actionConstants.GET_AI_RECENT_FAILURES),
        payload: retData
      });
    } catch (error) {
      dispatch({ type: failReqActionType(actionConstants.GET_AI_RECENT_FAILURES) });
    }
  };