import { useState, useEffect } from 'react';
import './CustomerDetailPage.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Dropdown,
  Badge,
} from 'react-bootstrap';
import {
  addCustomer,
  addCustomerMember,
  deleteCustomer,
  deleteCustomerMember,
  getCustomerDetail,
  regenerateAPIKey,
  updateCustomer
} from 'redux-layer/actions';
import { IMPORTANT_CUSTOMERS } from 'helper/constants';
import MembersTable from './MembersTable';
import AddUserModal from './AddUserModal';
import StatisticsSection from './Statistics';

const CustomerDetailPage = props => {
  const { customer_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allCustomers } = useSelector(state => ({
    allCustomers: state.customers.allCustomers
  }));

  const [customerName, setCustomerName] = useState('');
  const [customerID, setCustomerID] = useState('');
  const [customerUsername, setCustomerUsername] = useState('');
  const [managerName, setManagerName] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [newUser, setNewUser] = useState({
    username: '',
    role: ''
  });

  const currentCustomer = allCustomers[customer_id];

  useEffect(() => {
    if (customer_id && customer_id !== 'new') {
      dispatch(getCustomerDetail(customer_id));
    }
  }, [customer_id, dispatch]);

  useEffect(() => {
    if (currentCustomer) {
      setCustomerName(currentCustomer.name);
      setCustomerID(currentCustomer.customer_id);
      setCustomerUsername(currentCustomer.username);
    }
  }, [currentCustomer]);

  const handleSubmit = () => {
    const payload = {
      name: customerName,
      username: customerUsername
    };
    if (currentCustomer) {
      dispatch(updateCustomer(customer_id, payload));
    } else {
      payload['customer_id'] = customerID;
      payload['manager_name'] = managerName;
      dispatch(
        addCustomer(payload, newID => {
          navigate(`/customers/${newID}`);
        })
      );
    }
  };

  const onDeleteCustomer = () => {
    if (window.confirm('Do you really want to delete this customer?')) {
      dispatch(deleteCustomer(customer_id));
    }
    navigate('/customers');
  };

  const onChangeAPIKey = () => {
    if (
      window.confirm(
        'If API Key is changed, all APIs from customers need to get updated. Are you sure?'
      )
    ) {
      dispatch(regenerateAPIKey(customer_id));
    }
  };

  const customersOptions = Object.values(allCustomers).map(co => ({
    value: co.id,
    label: co.name,
    customer_id: co.customer_id,
    customer_key: co.key || ''
  }));

  const onViewContents = () => {
    const selectedCustomer = customersOptions.find(
      co => co.value === customer_id
    );
    if (selectedCustomer) {
      localStorage.setItem('customer', JSON.stringify(selectedCustomer));
      navigate('/media');
    }
  };

  const handleAddUser = () => {
    const payload = {
      member_id: newUser.username,
      role: newUser.role,
      customer_id
    };
    dispatch(
      addCustomerMember(payload, () => {
        dispatch(getCustomerDetail(customer_id));
      })
    );
    setShowAddUserModal(false);
  };

  const handleRemoveUser = username => {
    dispatch(
      deleteCustomerMember(customer_id, username, () => {
        dispatch(getCustomerDetail(customer_id));
      })
    );
  };

  return (
    <div className='customer-detail-page'>
      <div className='page-header'>
        <h1>
          {customer_id === 'new'
            ? 'New Customer'
            : currentCustomer
            ? currentCustomer.name
            : ''}
          {currentCustomer &&
            IMPORTANT_CUSTOMERS.includes(currentCustomer.customer_id) && (
              <Badge bg='danger' className='ms-2'>
                IMPORTANT CUSTOMER
              </Badge>
            )}
        </h1>
        <div className='actions'>
          {currentCustomer && (
            <Dropdown
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Dropdown.Toggle variant='primary' id='dropdown-play' size='md'>
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='statistics'
                  as={Link}
                  to={`/statistics/customers/${currentCustomer.customer_id}`}
                >
                  Statistics
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey='view-contents'
                  onClick={onViewContents}
                >
                  View Contents
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey='delete' onClick={onDeleteCustomer}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel required>Customer ID</FormLabel>
              <FormControl
                type='text'
                value={customerID}
                disabled={currentCustomer}
                required
                placeholder='Customer ID'
                onChange={e => setCustomerID(e.target.value)}
              />
              <FormLabel>
                <small>
                  Customer ID will be used to store files on S3. You can not
                  change customer id once customer is created.
                </small>
              </FormLabel>
            </FormGroup>
          </div>
          <div className='col-md-4'>
            {currentCustomer && (
              <FormGroup>
                <FormLabel>API Key</FormLabel>
                <FormControl disabled value={currentCustomer.key} />
              </FormGroup>
            )}
          </div>
          <div className='col-md-2'>
            {currentCustomer && (
              <FormGroup>
                <FormLabel>Change API Key?</FormLabel>
                <Button variant='danger' onClick={onChangeAPIKey}>
                  Change API Key
                </Button>
              </FormGroup>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <FormGroup>
              <FormLabel required>Business Name</FormLabel>
              <FormControl
                type='text'
                value={customerName}
                required
                placeholder='Business Name'
                onChange={e => setCustomerName(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-4'>
            <FormGroup>
              <FormLabel required>Owner of the Business</FormLabel>
              <FormControl
                type='text'
                value={customerUsername}
                required
                placeholder='Username of Owner'
                onChange={e => setCustomerUsername(e.target.value)}
              />
              <small>
                You will need this field to bill usage to this user.
              </small>
            </FormGroup>
            {currentCustomer && currentCustomer.username ? (
              <FormGroup>
                <Link
                  to={`/users/${currentCustomer.username}`}
                  className='btn btn-primary'
                >
                  Detail
                </Link>
              </FormGroup>
            ) : (
              ''
            )}
          </div>
          {!currentCustomer ||
          !currentCustomer.members ||
          !currentCustomer.members.length ? (
            <div className='col-md-4'>
              <FormGroup>
                <FormLabel required>Business Manager User ID</FormLabel>
                <FormControl
                  type='text'
                  value={managerName}
                  required
                  placeholder='Username of Manager'
                  onChange={e => setManagerName(e.target.value)}
                />
                <small>
                  You will need this field to let your B2B customers see their
                  console page.
                </small>
              </FormGroup>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Button onClick={handleSubmit} variant='primary'>
              Save
            </Button>
          </div>
        </div>
        {currentCustomer ? (
          <>
            <hr />
            <MembersTable
              currentCustomer={currentCustomer}
              handleRemoveUser={handleRemoveUser}
              setShowAddUserModal={setShowAddUserModal}
            />
            <AddUserModal
              showAddUserModal={showAddUserModal}
              setShowAddUserModal={setShowAddUserModal}
              newUser={newUser}
              setNewUser={setNewUser}
              handleAddUser={handleAddUser}
            />
            <hr />
            <StatisticsSection currentCustomer={currentCustomer} />
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export { CustomerDetailPage };
