import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MembersTable = ({
  currentCustomer,
  handleRemoveUser,
  setShowAddUserModal
}) => {
  return (
    <div>
      <h2 className='mb-2'>Members</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCustomer &&
            currentCustomer.members &&
            currentCustomer.members.map(member => {
              if (!member.detail) {
                return <tr></tr>;
              }
              const { detail } = member;

              const email = detail.UserAttributes.find(
                attr => attr.Name === 'email'
              )?.Value;
              const firstName = detail.UserAttributes.find(
                attr => attr.Name === 'given_name'
              )?.Value;
              const lastName = detail.UserAttributes.find(
                attr => attr.Name === 'family_name'
              )?.Value;

              return (
                <tr key={member.username}>
                  <td>{email}</td>
                  <td>{member.username}</td>
                  <td>{firstName}</td>
                  <td>{lastName}</td>
                  <td>{member.role}</td>
                  <td>
                    <Link
                      to={`/users/${member.username}`}
                      className='btn btn-primary me-2'
                    >
                      Detail
                    </Link>
                    <Button
                      variant='danger'
                      onClick={() => handleRemoveUser(member.username)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Button variant='primary' onClick={() => setShowAddUserModal(true)}>
        Add User
      </Button>
    </div>
  );
};

export default MembersTable;
