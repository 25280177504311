const actionConstants = {
  GET_CONTENTS: 'GET_CONTENTS',
  GET_CONTENT: 'GET_CONTENT',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  SUBMIT_3D: 'SUBMIT_3D',
  SUBMIT_BULLET: 'SUBMIT_BULLET',
  DELETE_CONTENT: 'DELETE_CONTENT',
  COPY_CONTENT: 'COPY_CONTENT',
  GET_USER_SUBMISSIONS_LOGS: 'GET_USER_SUBMISSIONS_LOGS',

  GET_SESSIONS: 'GET_SESSIONS',
  GET_SESSION: 'GET_SESSION',
  UPDATE_SESSION: 'UPDATE_SESSION',
  GET_FILE_UPLOAD_PATH: 'GET_FILE_UPLOAD_PATH',
  REMOVE_UPLOADED_FILE: 'REMOVE_UPLOADED_FILE',
  CREATE_SESSION: 'CREATE_SESSION',
  DELETE_SESSION: 'DELETE_SESSION',

  LIST_MEDIA: 'LIST_MEDIA',
  LIST_MEDIA_BY_USERNAME: 'LIST_MEDIA_BY_USERNAME',
  LIST_MEDIA_BY_CUSTOMER_ID: 'LIST_MEDIA_BY_CUSTOMER_ID',
  UPDATE_MEDIA: 'UPDATE_MEDIA',
  GET_MEDIA: 'GET_MEDIA',
  DELETE_MEDIA: 'DELETE_MEDIA',
  SUBMIT_MEDIA: 'SUBMIT_MEDIA',
  PAGINATED_LIST_MEDIAS: 'PAGINATED_LIST_MEDIAS',
  GET_MEDIAS_BY_USERNAME_BY_PAGE: 'GET_MEDIAS_BY_USERNAME_BY_PAGE',

  LIST_PRESET: 'LIST_PRESET',

  SET_ACTION_REQUIRED: 'SET_ACTION_REQUIRED',

  GET_USERS: 'GET_USERS',
  GET_USER_DETAIL: 'GET_USER_DETAIL',
  CREATE_UPDATE_USER: 'CREATE_UPDATE_USER',
  UPDATE_USER_CREDIT: 'UPDATE_USER_CREDIT',
  UPDATE_USER_DISCOUNT: 'UPDATE_USER_DISCOUNT',

  GET_USER_API_KEYS: 'GET_USER_API_KEYS',

  DELETE_USER: 'DELETE_USER',
  DISABLE_USER: 'DISABLE_USER',
  ENABLE_USER: 'ENABLE_USER',

  GET_DEVELOPER: 'GET_DEVELOPER',
  ADD_DEVELOPER: 'ADD_DEVELOPER',
  DELETE_DEVELOPER: 'DELETE_DEVELOPER',

  GET_ALL_GROUPS: 'GET_ALL_GROUPS',
  CREATE_GROUP: 'CREATE_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',

  GET_USERS_BY_GROUP: 'GET_USERS_BY_GROUP',
  GET_GROUPS_BY_USER: 'GET_GROUPS_BY_USER',
  ADD_USER_TO_GROUP: 'ADD_USER_TO_GROUP',
  REMOVE_USER_FROM_GROUP: 'REMOVE_USER_FROM_GROUP',

  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMER_DETAIL: 'GET_CUSTOMER_DETAIL',
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  REMOVE_CUSTOMER: 'REMOVE_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  REGENERATE_API_KEY_CUSTOMER: 'REGENERATE_API_KEY_CUSTOMER',
  ADD_CUSTOMER_MEMBER: 'ADD_CUSTOMER_MEMBER',
  DELETE_CUSTOMER_MEMBER: 'DELETE_CUSTOMER_MEMBER',

  GET_TOOLS: 'GET_TOOLS',
  GET_TOOL: 'GET_TOOL',
  ADD_TOOL: 'ADD_TOOL',
  REMOVE_TOOL: 'REMOVE_TOOL',
  UPDATE_TOOL: 'UPDATE_TOOL',

  GET_LAMBDAS: 'GET_LAMBDAS',
  ACT_ON_LAMBDA: 'ACT_ON_LAMBDA',

  GET_EC2_STATUS: 'GET_EC2_STATUS',
  GET_EC2_TOOLS_STATUS: 'GET_EC2_TOOLS_STATUS',
  ACT_ON_EC2: 'ACT_ON_EC2',
  GET_API_SERVERS: 'GET_API_SERVERS',

  REMOVE_UPLOADED_MEDIA_FILE: 'REMOVE_UPLOADED_MEDIA_FILE',

  PAGINATED_LIST_BATCHES: 'PAGINATED_LIST_BATCHES',

  GET_BATCH: 'GET_BATCH',
  DELETE_BATCH: 'DELETE_BATCH',
  UPDATE_BATCH: 'UPDATE_BATCH',
  PROCESS_BATCH: 'PROCESS_BATCH',
  GRAB_BATCH: 'GRAB_BATCH',
  GRAB_MEDIA_TO_BATCH: 'GRAB_MEDIA_TO_BATCH',

  GET_QUEUE_SIZES: 'GET_QUEUE_SIZES',
  PURGE_QUEUE: 'PURGE_QUEUE',

  GET_AI_RECENT_USAGE: 'GET_AI_RECENT_USAGE',
  GET_AI_RECENT_FAILURES: 'GET_AI_RECENT_FAILURES',

  GET_STATISTICS: 'GET_STATISTICS',

  GET_SETTINGS: 'GET_SETTINGS',
  UPDATE_SETTING: 'UPDATE_SETTING',

  GET_SERVICE_BALANCES: 'GET_SERVICE_BALANCES',

  PAGINATED_LIST_ASSETS: 'PAGINATED_LIST_ASSETS',
  GET_ASSET_DETAIL: 'GET_ASSET_DETAIL',

  PAGINATED_LIST_COUPONS: 'PAGINATED_LIST_COUPONS',
  CREATE_AND_UPDATE_COUPON: 'CREATE_AND_UPDATE_COUPON',
  GET_COUPON_DETAIL: 'GET_ASSET_COUPON',
  DELETE_COUPON: 'DELETE_COUPON',

  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE'
};

export { actionConstants };
